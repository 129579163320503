import React from "react";
import PropTypes from "prop-types";

import "./faq.css";

const FAQ = ({ data: { faq_headline1: headline, faq_blocks1: blocks } }) => (
  <section className="faq">
    <div className="container">
      <h1 className="faq__headline">{headline.text}</h1>
      <ul className="faq__blocks">
        {blocks.map(block => (
          <li key={block.faq_question1.text} className="faq__block">
            <h5 className="faq__block-headline">{block.faq_question1.text}</h5>
            <div
              className="faq__block-content"
              dangerouslySetInnerHTML={{
                __html: block.faq_answer1.html
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  </section>
);

FAQ.propTypes = {
  data: PropTypes.shape({
    faq_headline1: PropTypes.object.isRequired,
    faq_blocks1: PropTypes.array.isRequired
  }).isRequired
};

export default FAQ;

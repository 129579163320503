import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Header from "../components/Header";
import FAQ from "../components/FAQ";
import LocalizedLink from "../components/LocalizedLink";

const FaqPage = ({ data: { faq } }) => (
  <main>
    <Header>
      <LocalizedLink to="/">Home</LocalizedLink>
    </Header>
    <FAQ data={faq.data} />
  </main>
);

FaqPage.propTypes = {
  data: PropTypes.shape({
    faq: PropTypes.object.isRequired
  }).isRequired
};

export const pageQuery = graphql`
  query FaqQuery($locale: String!) {
    faq: prismicFaq(lang: { eq: $locale }) {
      data {
        faq_headline1 {
          text
        }
        faq_blocks1 {
          faq_question1 {
            text
          }
          faq_answer1 {
            html
          }
        }
      }
    }
  }
`;

export default FaqPage;

import React from "react";
import PropTypes from "prop-types";
import LocalizedLink from "../LocalizedLink";

const MobileMenu = ({ isOpen, onClick, data, showProductsTab }) => (
  <div className={`header__mobile-menu${isOpen ? " header__opened" : ""}`}>
    <ul className="header__mobile-list">
      <li className="header__mobile-item">
        <LocalizedLink className="header__link" to="/#bullit" onClick={onClick}>
          {data.nav_hero}
        </LocalizedLink>
      </li>
      <li className="header__mobile-item">
        <LocalizedLink
          className="header__link"
          to="/#ingredients"
          onClick={onClick}
        >
          {data.nav_intro}
        </LocalizedLink>
      </li>
      {showProductsTab && (
        <li className="header__mobile-item">
          <LocalizedLink
            className="header__link"
            to="/#bullit-cans"
            onClick={onClick}
          >
            {data.nav_products}
          </LocalizedLink>
        </li>
      )}
      <li className="header__mobile-item">
        <LocalizedLink
          className="header__link"
          to="/#contact"
          onClick={onClick}
        >
          {data.nav_contact}
        </LocalizedLink>
      </li>
      <li className="header__mobile-item">
        <LocalizedLink className="header__link" to="/faq">
          {data.nav_faq}
        </LocalizedLink>
      </li>
      <li className="header__mobile-item">
        <LocalizedLink className="header__link" to="/privacy-policy">
          {data.nav_policy}
        </LocalizedLink>
      </li>
      <li className="header__mobile-item">
        <LocalizedLink className="header__link" to="/cookies-policy">
          {data.nav_cookies}
        </LocalizedLink>
      </li>
    </ul>
  </div>
);

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  showProductsTab: PropTypes.bool
};

MobileMenu.defaultProps = {
  showProductsTab: true
};

export default MobileMenu;
